
import { defineComponent, ref, computed } from 'vue'
import Logo from '@/components/particles/common/Logo.vue'
import AuthSubmitButton from '@/components/particles/AuthLayout/AuthSubmitButton.vue'
import SmsCodeInput from '@/components/particles/forms/ForgetPasswordForm/SmsCodeInput.vue'
import useStore from '@/store'
import { AUTH_ACTION_TYPES } from '@/store/auth/actions'
import { AUTH_MUTATION_TYPES } from '@/store/auth/mutations'
import { useForm, useIsSubmitting } from 'vee-validate'

export default defineComponent({
  components: {
    SmsCodeInput,
    AuthSubmitButton,
    Logo,
  },
  emits: ['update-component'],
  setup(props, { emit }) {
    const store = useStore()
    const { handleSubmit } = useForm()
    const smsCode = ref<string>('')

    const smsCodeValue = (code: string) => {
      smsCode.value = code
    }

    const userId = computed(() => store.state.auth.userInfo.user_id)

    const onSubmit = handleSubmit(async () => {
      try {
        await store.dispatch(AUTH_ACTION_TYPES.CHECK_SMS, {
          user: userId.value,
          verification_code: smsCode.value,
        })
        store.commit(AUTH_MUTATION_TYPES.SET_SMS_CODE, smsCode.value)
        emit('update-component', 'ForgetPasswordNewPassword')
      } catch (error: any) {
        if (error?.request?.status === 400) {
          //error
        }
      }
    })

    return {
      onSubmit,
      smsCodeValue,
      smsCode,
      isSubmitting: useIsSubmitting(),
    }
  },
})
