import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "autofocus", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputs, (v) => {
    return (_openBlock(), _createElementBlock("input", {
      key: v.id,
      class: "max-w-[48px] w-full h-[50px] outline-none rounded-sm border border-grey text-center text-base-title font-semibold focus:border-darkGrey",
      type: "tel",
      pattern: "[0-9]*",
      id: v.id,
      ref: _ctx.setItemRef,
      autofocus: _ctx.autoFocusIndex,
      value: v.value,
      maxlength: "1",
      autocomplete: "off",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onValueChange && _ctx.onValueChange(...args))),
      onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args)))
    }, null, 40, _hoisted_1))
  }), 128))
}