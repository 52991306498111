import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-2.5 text-center font-medium text-sm" }
const _hoisted_2 = { class: "mb-[30px] text-base text-center" }
const _hoisted_3 = { class: "mb-5 flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_SmsCodeInput = _resolveComponent("SmsCodeInput")!
  const _component_AuthSubmitButton = _resolveComponent("AuthSubmitButton")!
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
    class: "max-w-[398px] max-h-md w-full pt-10 px-5 pb-8 bg-white rounded-lg"
  }, [
    _createVNode(_component_Logo, { class: "mb-[30px]" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_TITLE')), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('FORGET_PASSWORD_INSERT_SMS_CODE')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SmsCodeInput, { onSmsCode: _ctx.smsCodeValue }, null, 8, ["onSmsCode"])
    ]),
    _createVNode(_component_AuthSubmitButton, {
      class: "mb-2.5",
      loading: _ctx.isSubmitting,
      disabled: _ctx.smsCode.length < 6
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_SUBMIT_BUTTON')), 1)
      ]),
      _: 1
    }, 8, ["loading", "disabled"]),
    _createVNode(_component_UiButton, {
      class: "py-4 bg-lightGrey text-darkGrey w-full text-sm hover:text-red",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update-component', 'ForgetPasswordSmsCodeSent')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_RETURN_BUTTON')), 1)
      ]),
      _: 1
    })
  ], 32))
}