
import { defineComponent, ref, ComponentPublicInstance } from 'vue'
import { KEY_CODE } from '@/constants'

export default defineComponent({
  name: 'CodeInput',
  props: {
    fields: {
      type: Number,
      default: 6,
    },
  },
  emits: ['sms-code'],
  setup(props, { emit }) {
    interface objToAdd {
      value: string
      id: number
    }
    const autoFocusIndex = ref(1)
    const inputs = ref<objToAdd[]>([])
    const itemRefs = ref<ComponentPublicInstance<HTMLInputElement>[]>([])

    for (let i = 0; i < props.fields; i++) {
      inputs.value.push({ value: '', id: i })
    }

    const setItemRef = (inputEl: ComponentPublicInstance<HTMLInputElement>) => {
      if (inputEl) {
        itemRefs.value.push(inputEl)
      }
    }

    const numberValidation = (str: string) => {
      return /[^\d]/gi.test(str)
    }

    const onValueChange = (e: Event) => {
      //clear to left only numbers

      if (numberValidation((e.target as HTMLInputElement).value)) {
        ;(e.target as HTMLInputElement).value = (
          e.target as HTMLInputElement
        ).value.replace(/[^\d]/gi, '')
      }

      //to storage the inserted value
      inputs.value.forEach((el: objToAdd) => {
        if (el.id.toString() == (e.target as HTMLInputElement).id) {
          el.value = (e.target as HTMLInputElement).value

          //while itemRefs.length > el.id focus on the next el
          if (itemRefs.value.length > el.id) itemRefs.value[el.id + 1].focus()
        }
      })
      const smsCode = inputs.value.map((el) => el.value).join('')
      emit('sms-code', smsCode)
    }

    const onKeyDown = (e: KeyboardEvent) => {
      const index = parseInt((e.target as HTMLInputElement).id)
      const prevIndex = index - 1
      const nextIndex = index + 1
      switch (e.key) {
        case KEY_CODE.backspace: {
          e.preventDefault()
          if (inputs.value[index].id >= 0) {
            inputs.value[index].value = ''
            if (inputs.value[index].id > 0) {
              itemRefs.value[prevIndex].focus()
            }
            const smsCode = inputs.value.map((el) => el.value).join('')
            emit('sms-code', smsCode)
          }
          break
        }
        case KEY_CODE.left: {
          e.preventDefault()

          //if first one in arr => focus on the last one, else can focus anywhere
          prevIndex >= 0
            ? itemRefs.value[prevIndex].focus()
            : itemRefs.value[itemRefs.value.length - 1].focus()
          break
        }
        case KEY_CODE.right: {
          e.preventDefault()

          //if last one in arr => focus on the first one, else can focus anywhere
          nextIndex === itemRefs.value.length
            ? itemRefs.value[0].focus()
            : itemRefs.value[nextIndex].focus()

          break
        }
        case KEY_CODE.up: {
          e.preventDefault()
          break
        }
        case KEY_CODE.down: {
          e.preventDefault()
          break
        }
        default:
          break
      }
    }

    return {
      inputs,
      autoFocusIndex,
      onValueChange,
      setItemRef,
      onKeyDown,
    }
  },
})
